import api from '@/plugins/axios';

export const DownloadRssFeed = async (url: string): Promise<any> => {
    const { data } = await api.get<any>('/rss', {
        params: {
            url
        }
    })

    return data;
}
