
import { Component, Vue } from 'vue-property-decorator';
import * as RssServices from '@/services/RssServices';

@Component
export default class RssFeeds extends Vue {
    feed = null as any;
    selected = null as any;
    url = 'https://www.electifacts.eu/rss'

    async mounted() {
        await this.fetchData()
    }

    async fetchData() {
        this.feed = await RssServices.DownloadRssFeed(this.url)
    }

    openItem(index: number) {
        this.selected = this.items[index]
        // save the id in localstorage
        let currentStorage = localStorage.getItem('rss')
        if (!currentStorage) {
            localStorage.setItem('rss', JSON.stringify([this.selected.id]))
        } else {
            let storage = JSON.parse(currentStorage)
            storage.push(this.selected.id)
            localStorage.setItem('rss', JSON.stringify(storage))
        }
    }

    get items() {
        if (!this.feed) return []
        return this.feed.rss.channel.item.map((item: any) => {
            return {
                id: item.guid._text,
                title: item.title._text,
                image: item['media:content']._attributes.url,
                date: item.pubDate._text,
                content: item['content:encoded']._cdata,
                url: item.link._text
            }
        })
    }
}

